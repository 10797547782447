import React from 'react';

const RecatIcon = ({ width = '512', height = '512' }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width={width} height={height}>
			<circle cx='32' cy='33' r='6' fill='#ed7899' />
			<path
				fill='#8d6c9f'
				d='M32,40c-3.859,0-7-3.141-7-7s3.141-7,7-7s7,3.141,7,7S35.859,40,32,40z M32,28 c-2.757,0-5,2.243-5,5s2.243,5,5,5s5-2.243,5-5S34.757,28,32,28z'
			/>
			<path
				fill='#8d6c9f'
				d='M32,46C15.178,46,2,40.29,2,33s13.178-13,30-13s30,5.71,30,13S48.822,46,32,46z M32,22 C16.822,22,4,27.037,4,33s12.822,11,28,11s28-5.037,28-11S47.178,22,32,22z'
			/>
			<path
				fill='#8d6c9f'
				d='M20.316,59.827c-1.222,0-2.335-0.28-3.316-0.847c-3.149-1.818-4.425-6.291-3.593-12.596 c0.793-6.004,3.397-13.065,7.334-19.885c3.938-6.819,8.752-12.606,13.555-16.295C39.341,6.332,43.853,5.202,47,7.02l0,0 c3.149,1.818,4.425,6.291,3.593,12.596C49.8,25.619,47.195,32.681,43.259,39.5c-3.938,6.819-8.752,12.606-13.555,16.295 C26.232,58.46,23.013,59.827,20.316,59.827z M43.684,8.179c-2.234,0-5.079,1.239-8.17,3.613c-4.6,3.532-9.23,9.11-13.04,15.708 c-3.809,6.598-6.324,13.396-7.084,19.146c-0.709,5.371,0.243,9.234,2.61,10.602s6.187,0.26,10.486-3.04 c4.6-3.532,9.23-9.11,13.04-15.708c3.809-6.598,6.324-13.396,7.084-19.146c0.709-5.371-0.243-9.234-2.61-10.602l0,0 C45.336,8.368,44.556,8.179,43.684,8.179z'
			/>
			<path
				fill='#8d6c9f'
				d='M43.684,59.827c-2.696,0-5.916-1.367-9.388-4.032c-4.803-3.688-9.617-9.476-13.555-16.295 c-3.937-6.819-6.541-13.881-7.334-19.885C12.575,13.311,13.851,8.838,17,7.02c3.15-1.816,7.661-0.686,12.704,3.186 c4.803,3.688,9.617,9.476,13.555,16.295c3.937,6.819,6.541,13.881,7.334,19.885C51.425,52.689,50.149,57.162,47,58.98 C46.019,59.547,44.904,59.827,43.684,59.827z M20.316,8.179c-0.873,0-1.651,0.189-2.316,0.573l0,0 c-2.367,1.367-3.319,5.23-2.61,10.602c0.76,5.75,3.275,12.549,7.084,19.146c3.81,6.598,8.44,12.176,13.04,15.708 c4.298,3.3,8.119,4.407,10.486,3.04s3.319-5.23,2.61-10.602c-0.76-5.75-3.275-12.549-7.084-19.146 c-3.81-6.598-8.44-12.176-13.04-15.708C25.396,9.419,22.551,8.179,20.316,8.179z'
			/>
			<path
				fill='#8d6c9f'
				d='M10.463,46.972c-0.044,0-0.088-0.003-0.133-0.009l-1.982-0.264 c-0.548-0.073-0.933-0.575-0.859-1.123c0.072-0.548,0.572-0.933,1.123-0.859l1.982,0.264c0.548,0.073,0.933,0.575,0.859,1.123 C11.387,46.606,10.957,46.972,10.463,46.972z'
			/>
			<path
				fill='#8d6c9f'
				d='M8.194,51.885c-0.536,0-0.98-0.426-0.998-0.967c-0.019-0.552,0.413-1.014,0.966-1.032 l1.998-0.067c0.551-0.037,1.015,0.413,1.033,0.966c0.019,0.552-0.413,1.014-0.966,1.032l-1.999,0.067 C8.218,51.885,8.206,51.885,8.194,51.885z'
			/>
			<path
				fill='#8d6c9f'
				d='M9.009,57.093c-0.429,0-0.825-0.277-0.956-0.708c-0.161-0.528,0.136-1.087,0.664-1.248 l1.913-0.584c0.53-0.166,1.088,0.136,1.248,0.664c0.162,0.528-0.136,1.087-0.664,1.248l-1.913,0.584 C9.203,57.079,9.105,57.093,9.009,57.093z'
			/>
			<path
				fill='#8d6c9f'
				d='M11.552,61.852c-0.281,0-0.561-0.118-0.759-0.349c-0.359-0.419-0.312-1.051,0.107-1.41 l1.518-1.303c0.418-0.358,1.049-0.312,1.41,0.107c0.359,0.419,0.312,1.051-0.107,1.41l-1.518,1.303 C12.015,61.772,11.783,61.852,11.552,61.852z'
			/>
			<path
				fill='#8d6c9f'
				d='M55.771,18.718c-0.02,0-0.039-0.001-0.059-0.002l-1.996-0.115 c-0.552-0.031-0.973-0.504-0.94-1.056c0.031-0.551,0.507-0.971,1.056-0.94l1.996,0.115c0.552,0.031,0.973,0.504,0.94,1.056 C56.737,18.307,56.296,18.718,55.771,18.718z'
			/>
			<path
				fill='#8d6c9f'
				d='M53.591,13.797c-0.487,0-0.913-0.355-0.988-0.852c-0.082-0.546,0.295-1.056,0.841-1.138 l1.978-0.297c0.548-0.072,1.056,0.296,1.138,0.841c0.082,0.546-0.295,1.056-0.841,1.138l-1.978,0.297 C53.69,13.793,53.64,13.797,53.591,13.797z'
			/>
			<path
				fill='#8d6c9f'
				d='M52.215,9.336c-0.36,0-0.708-0.194-0.887-0.536c-0.257-0.488-0.067-1.093,0.422-1.35l1.771-0.929 c0.488-0.256,1.093-0.068,1.35,0.422c0.257,0.488,0.067,1.093-0.422,1.35l-1.771,0.929C52.53,9.299,52.371,9.336,52.215,9.336z'
			/>
			<path
				fill='#8d6c9f'
				d='M49.266,5.919c-0.2,0-0.402-0.06-0.578-0.185c-0.45-0.32-0.556-0.944-0.236-1.395l1.158-1.631 c0.32-0.45,0.943-0.557,1.395-0.236c0.45,0.32,0.556,0.944,0.236,1.395l-1.158,1.631C49.887,5.772,49.579,5.919,49.266,5.919z'
			/>
		</svg>
	);
};

const HTMLIcon = ({ width = '512', height = '512' }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width={width} height={height}>
			<path
				fill='#f6d397'
				d='M5.015,7.244l5.466,45.246c0.194,1.603,1.337,2.935,2.9,3.379l17.535,4.977 c0.709,0.201,1.46,0.206,2.172,0.013l18.442-4.996c1.614-0.437,2.789-1.817,2.955-3.471l4.549-45.187C59.153,6.024,58.22,5,57.025,5 H7.019C5.809,5,4.87,6.051,5.015,7.244z'
			/>
			<path
				fill='#f9e3ae'
				d='M57.025,5H32v56c0.366,0.002,0.732-0.045,1.088-0.141l18.442-4.996 c1.614-0.437,2.789-1.817,2.955-3.471l4.549-45.187C59.153,6.024,58.22,5,57.025,5z'
			/>
			<path
				fill='#8d6c9f'
				d='M32.025,62c-0.465,0-0.931-0.064-1.383-0.192l-17.535-4.977c-1.955-0.555-3.376-2.211-3.62-4.221 L4.021,7.364c-0.103-0.85,0.165-1.705,0.733-2.347C5.328,4.371,6.153,4,7.02,4h50.006c0.854,0,1.672,0.362,2.244,0.995 c0.567,0.628,0.845,1.47,0.76,2.311L55.48,52.493c-0.208,2.052-1.69,3.794-3.69,4.334l-18.44,4.996 C32.916,61.941,32.471,62,32.025,62z M7.02,6C6.721,6,6.448,6.122,6.252,6.344C6.06,6.561,5.973,6.838,6.008,7.124l0,0l5.466,45.246 c0.146,1.207,1.002,2.203,2.18,2.537l17.536,4.977c0.535,0.151,1.1,0.155,1.637,0.01l18.441-4.996 c1.205-0.326,2.099-1.373,2.223-2.605l4.549-45.188c0.028-0.283-0.062-0.556-0.253-0.769C57.59,6.119,57.319,6,57.025,6H7.02z'
			/>
			<path
				fill='#8d6c9f'
				d='M7 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C8 11.553 7.553 12 7 12zM12 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C13 11.553 12.553 12 12 12zM17 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C18 11.553 17.553 12 17 12zM22 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C23 11.553 22.553 12 22 12zM27 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C28 11.553 27.553 12 27 12zM32 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C33 11.553 32.553 12 32 12zM37 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C38 11.553 37.553 12 37 12zM42 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C43 11.553 42.553 12 42 12zM47 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C48 11.553 47.553 12 47 12zM52 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C53 11.553 52.553 12 52 12zM57 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C58 11.553 57.553 12 57 12z'
			/>
			<path
				fill='#faefde'
				d='M47.018,17H17c-0.614,0-1.083,0.548-0.987,1.155l2.52,16.001C18.61,34.642,19.029,35,19.521,35h19.31 c0.599,0,1.063,0.523,0.993,1.117l-0.769,6.508l-6.961,2.122l-6.147-2.082l-0.444-2.821C25.426,39.358,25.007,39,24.515,39h-4.049 c-0.614,0-1.083,0.549-0.988,1.156l1.014,6.439c0.057,0.362,0.31,0.663,0.658,0.78l10.553,3.574C31.804,50.983,31.908,51,32.013,51 c0.095,0,0.19-0.014,0.282-0.042l11.643-3.548c0.374-0.114,0.646-0.439,0.691-0.827l1.945-16.465C46.645,29.523,46.181,29,45.582,29 H24.65c-0.492,0-0.911-0.358-0.988-0.844l-0.63-4C22.936,23.549,23.405,23,24.02,23h22.507c0.507,0,0.934-0.379,0.993-0.883 l0.475-4.019C48.064,17.514,47.607,17,47.018,17z'
			/>
			<path
				fill='#8d6c9f'
				d='M32.014 52c-.211 0-.421-.034-.624-.102l-10.56-3.576c-.701-.236-1.209-.838-1.325-1.57l-1.015-6.441c-.091-.578.075-1.165.455-1.61.381-.445.935-.7 1.521-.7h4.05c.991 0 1.822.711 1.976 1.69l.35 2.222 5.27 1.784 6.029-1.837L38.831 36H19.521c-.991 0-1.822-.711-1.976-1.69l-2.521-16c-.091-.577.075-1.164.455-1.609C15.86 16.255 16.414 16 17 16h30.019c.565 0 1.105.242 1.481.665.376.422.554.987.488 1.55l-.475 4.02C48.395 23.241 47.541 24 46.527 24H24.02l.631 4h20.932c.571 0 1.116.245 1.495.672.379.426.559.996.491 1.563l-1.945 16.465c-.093.784-.64 1.438-1.395 1.667l-11.641 3.549C32.402 51.972 32.209 52 32.014 52zM20.466 40l1.015 6.439 10.543 3.563 11.623-3.55L45.582 30H24.649c-.991 0-1.822-.711-1.976-1.69l-.63-3.999c-.091-.578.075-1.165.455-1.61.381-.445.935-.7 1.521-.7h22.508L47 18H17l2.521 16h19.31c.571 0 1.116.245 1.495.672.379.426.559.996.491 1.563l-.769 6.508c-.047.395-.322.724-.701.839l-6.961 2.122c-.201.062-.415.059-.612-.009l-6.147-2.082c-.353-.119-.609-.424-.668-.792L24.515 40H20.466zM39.999 54.806c-.44 0-.844-.293-.964-.738-.145-.533.17-1.082.703-1.227l2-.542c.534-.146 1.083.17 1.227.703.145.533-.17 1.082-.703 1.227l-2 .542C40.174 54.795 40.086 54.806 39.999 54.806zM32.024 57.003l-5.298-1.504c-.531-.15-.84-.704-.688-1.235.15-.531.701-.842 1.235-.688l4.763 1.352 3.702-1.002c.534-.148 1.083.17 1.227.703.145.533-.17 1.083-.703 1.227L32.024 57.003z'
			/>
			<path
				fill='#faefde'
				d='M50.5 23l1.238 2.262L54 26.5l-2.262 1.238L50.5 30l-1.238-2.262L47 26.5l2.262-1.238L50.5 23zM47.502 47l.876 1.61L50 49.502l-1.622.876L47.502 52l-.892-1.622L45 49.502l1.61-.892L47.502 47zM12.498 35l.529.962.962.536-.962.529L12.498 38l-.536-.973L11 36.498l.962-.536L12.498 35z'
			/>
		</svg>
	);
};

const CSSIcon = ({ width = '512', height = '512' }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width={width} height={height}>
			<path
				fill='#7bbeeb'
				d='M5.015,7.244l5.466,45.246c0.194,1.603,1.337,2.935,2.9,3.379l17.535,4.977 c0.709,0.201,1.46,0.206,2.172,0.013l18.442-4.996c1.614-0.437,2.789-1.817,2.955-3.471l4.549-45.187C59.153,6.024,58.22,5,57.025,5 H7.019C5.809,5,4.87,6.051,5.015,7.244z'
			/>
			<path
				fill='#ace3ff'
				d='M57.025,5H32v56c0.366,0.002,0.732-0.045,1.088-0.141l18.442-4.996 c1.614-0.437,2.789-1.817,2.955-3.471l4.549-45.187C59.153,6.024,58.22,5,57.025,5z'
			/>
			<path
				fill='#8d6c9f'
				d='M32.025,62c-0.465,0-0.931-0.064-1.383-0.192l-17.535-4.977c-1.955-0.555-3.376-2.211-3.62-4.221 L4.021,7.364c-0.103-0.85,0.165-1.705,0.733-2.347C5.328,4.371,6.153,4,7.02,4h50.006c0.854,0,1.672,0.362,2.244,0.995 c0.567,0.628,0.845,1.47,0.76,2.311L55.48,52.493c-0.208,2.052-1.69,3.794-3.69,4.334l-18.44,4.996 C32.916,61.941,32.471,62,32.025,62z M7.02,6C6.721,6,6.448,6.122,6.252,6.344C6.06,6.561,5.973,6.838,6.008,7.124l0,0l5.466,45.246 c0.146,1.207,1.002,2.203,2.18,2.537l17.536,4.977c0.535,0.151,1.1,0.155,1.637,0.01l18.441-4.996 c1.205-0.326,2.099-1.373,2.223-2.605l4.549-45.188c0.028-0.283-0.062-0.556-0.253-0.769C57.59,6.119,57.319,6,57.025,6H7.02z'
			/>
			<path
				fill='#8d6c9f'
				d='M7 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C8 11.553 7.553 12 7 12zM12 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C13 11.553 12.553 12 12 12zM17 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C18 11.553 17.553 12 17 12zM22 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C23 11.553 22.553 12 22 12zM27 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C28 11.553 27.553 12 27 12zM32 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C33 11.553 32.553 12 32 12zM37 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C38 11.553 37.553 12 37 12zM42 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C43 11.553 42.553 12 42 12zM47 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C48 11.553 47.553 12 47 12zM52 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C53 11.553 52.553 12 52 12zM57 12c-.553 0-1-.447-1-1V9c0-.553.447-1 1-1s1 .447 1 1v2C58 11.553 57.553 12 57 12zM39.999 54.806c-.44 0-.844-.293-.964-.738-.145-.533.17-1.082.703-1.227l2-.542c.534-.146 1.083.17 1.227.703.145.533-.17 1.082-.703 1.227l-2 .542C40.174 54.795 40.086 54.806 39.999 54.806zM32.024 57.003l-5.298-1.504c-.531-.15-.84-.704-.688-1.235.15-.531.701-.842 1.235-.688l4.763 1.352 3.702-1.002c.534-.148 1.083.17 1.227.703.145.533-.17 1.083-.703 1.227L32.024 57.003z'
			/>
			<path
				fill='#fff8f8'
				d='M15.5 30l1.238 2.262L19 33.5l-2.262 1.238L15.5 37l-1.238-2.262L12 33.5l2.262-1.238L15.5 30zM47.502 47l.876 1.61L50 49.502l-1.622.876L47.502 52l-.892-1.622L45 49.502l1.61-.892L47.502 47zM52.504 23l.531.962L54 24.498l-.966.529L52.504 26l-.538-.973L51 24.498l.966-.536L52.504 23z'
			/>
			<path
				fill='#faefde'
				d='M47.018,17H17c-0.614,0-1.083,0.548-0.987,1.155L16.776,23h24.598l-0.709,6H25v6h14.956l-0.901,7.625 l-6.961,2.122l-6.147-2.082l-0.444-2.821C25.426,39.358,25.007,39,24.515,39h-4.049c-0.614,0-1.083,0.549-0.988,1.156l1.014,6.439 c0.057,0.362,0.31,0.663,0.658,0.78l10.553,3.574C31.804,50.983,31.908,51,32.013,51c0.095,0,0.19-0.014,0.282-0.042l11.643-3.548 c0.374-0.114,0.646-0.439,0.691-0.827l2.891-24.465l0.475-4.019C48.064,17.514,47.607,17,47.018,17z'
			/>
			<path
				fill='#8d6c9f'
				d='M32.014,52c-0.211,0-0.421-0.034-0.624-0.102l-10.56-3.576c-0.701-0.236-1.209-0.838-1.325-1.57 l-1.015-6.441c-0.091-0.578,0.075-1.165,0.455-1.61c0.381-0.445,0.935-0.7,1.521-0.7h4.05c0.991,0,1.822,0.711,1.976,1.69 l0.35,2.222l5.27,1.784l6.029-1.837L38.831,36H25c-0.553,0-1-0.447-1-1v-6c0-0.553,0.447-1,1-1h14.776l0.473-4H16.775 c-0.492,0-0.911-0.358-0.988-0.845l-0.763-4.846c-0.091-0.577,0.075-1.164,0.455-1.609C15.86,16.255,16.414,16,17,16h30.019 c0.566,0,1.106,0.243,1.483,0.666c0.375,0.423,0.553,0.988,0.486,1.551l-3.365,28.482c-0.093,0.782-0.639,1.437-1.393,1.666 l-11.643,3.55C32.402,51.972,32.209,52,32.014,52z M20.466,40l1.015,6.439l10.543,3.563l11.623-3.55L47,18H17l0.63,4h23.744 c0.286,0,0.558,0.122,0.747,0.336c0.19,0.213,0.279,0.498,0.246,0.781l-0.709,6C41.599,29.621,41.172,30,40.665,30H26v4h13.956 c0.285,0,0.558,0.122,0.747,0.336c0.19,0.213,0.279,0.498,0.246,0.781l-0.9,7.625c-0.047,0.395-0.322,0.724-0.701,0.839 l-6.961,2.122c-0.201,0.062-0.415,0.059-0.612-0.009l-6.147-2.082c-0.353-0.119-0.609-0.424-0.668-0.792L24.515,40H20.466z'
			/>
		</svg>
	);
};

const JSIcon = ({ width = '512', height = '512' }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 1052 1052'>
			<path fill='#f0db4f' d='M0 0h1052v1052H0z' />
			<path
				d='M965.9 801.1c-7.7-48-39-88.3-131.7-125.9-32.2-14.8-68.1-25.399-78.8-49.8-3.8-14.2-4.3-22.2-1.9-30.8 6.9-27.9 40.2-36.6 66.6-28.6 17 5.7 33.1 18.801 42.8 39.7 45.4-29.399 45.3-29.2 77-49.399-11.6-18-17.8-26.301-25.4-34-27.3-30.5-64.5-46.2-124-45-10.3 1.3-20.699 2.699-31 4-29.699 7.5-58 23.1-74.6 44-49.8 56.5-35.6 155.399 25 196.1 59.7 44.8 147.4 55 158.6 96.9 10.9 51.3-37.699 67.899-86 62-35.6-7.4-55.399-25.5-76.8-58.4-39.399 22.8-39.399 22.8-79.899 46.1 9.6 21 19.699 30.5 35.8 48.7 76.2 77.3 266.899 73.5 301.1-43.5 1.399-4.001 10.6-30.801 3.199-72.101zm-394-317.6h-98.4c0 85-.399 169.4-.399 254.4 0 54.1 2.8 103.7-6 118.9-14.4 29.899-51.7 26.2-68.7 20.399-17.3-8.5-26.1-20.6-36.3-37.699-2.8-4.9-4.9-8.7-5.601-9-26.699 16.3-53.3 32.699-80 49 13.301 27.3 32.9 51 58 66.399 37.5 22.5 87.9 29.4 140.601 17.3 34.3-10 63.899-30.699 79.399-62.199 22.4-41.3 17.6-91.3 17.4-146.6.5-90.2 0-180.4 0-270.9z'
				fill='#323330'
			/>
		</svg>
	);
};

const PythonIcon = ({ width = '512', height = '512' }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width={width} height={height}>
			<path
				fill='#85cbf8'
				d='M32,4c-9.927,0-13,4.709-13,7.764V17h13v4H11.764C7.818,21,4,23.855,4,32s3.818,11,7.764,11h7.509 v-5.909c0-2.8,2.291-5.091,5.091-5.091h15.273c2.8,0,5.364-2.291,5.364-5.091V11.764C45,8.582,41.927,4,32,4z'
			/>
			<path
				fill='#f9dd8f'
				d='M32,60c9.927,0,12.727-4.709,12.727-7.764V47H32v-4h20.236C56.182,43,60,40.145,60,32 s-3.818-11-7.764-11H45v5.909c0,2.8-2.564,5.091-5.364,5.091H24.364C21.564,32,19,34.291,19,37.091v15.145 C19,55.291,22.073,60,32,60z'
			/>
			<path
				fill='#8d6c9e'
				d='M26.5 9A2.5 2.5 0 1 0 26.5 14 2.5 2.5 0 1 0 26.5 9zM38.5 50A2.5 2.5 0 1 0 38.5 55 2.5 2.5 0 1 0 38.5 50z'
			/>
			<path
				fill='#ace3ff'
				d='M4,32c0,8.145,3.818,11,7.764,11h6.299c0-2.761-2.239-5-5-5h-1.299C9.726,38,9,34.9,9,32 s0.726-6,2.754-6l16.283,0.031c0.003,0,0.007,0,0.01,0c2.757,0,4.995-2.232,5-4.99c0-0.014-0.004-0.027-0.004-0.041H11.764 C7.818,21,4,23.855,4,32z'
			/>
			<path
				fill='#efc88e'
				d='M60.047,32c0-8.145-3.818-11-7.764-11h-6.299c0,2.761,2.239,5,5,5h1.299c2.038,0,2.764,3.1,2.764,6 s-0.726,6-2.754,6L36.01,37.969c-0.003,0-0.007,0-0.01,0c-2.757,0-4.995,2.232-5,4.99c0,0.014,0.004,0.027,0.004,0.041h21.279 C56.229,43,60.047,40.145,60.047,32z'
			/>
			<path
				fill='#8d6c9f'
				d='M20,44h-8.236C9.708,44,3,43.135,3,32s6.708-12,8.764-12H31v-2H18v-6.236C18,8.732,20.926,3,32,3 c11.052,0,14,5.509,14,8.764v15.146C46,30.211,43.086,33,39.637,33H24.364C22.039,33,20,34.912,20,37.091V44z M11.764,22 C7.465,22,5,25.645,5,32s2.465,10,6.764,10H18v-4.909C18,33.789,20.914,31,24.364,31h15.273C41.961,31,44,29.088,44,26.909V11.764 C44,9.251,41.473,5,32,5c-9.492,0-12,4.424-12,6.764V16h13v6H11.764z'
			/>
			<path
				fill='#8d6c9e'
				d='M32,61c-11.074,0-14-5.732-14-8.764V37.091C18,33.789,20.914,31,24.364,31h15.273 C41.961,31,44,29.088,44,26.909V20h8.236C54.292,20,61,20.865,61,32s-6.708,12-8.764,12H33v2h13v6.236C46,55.268,43.074,61,32,61z M24.364,33C22.039,33,20,34.912,20,37.091v15.146C20,54.576,22.508,59,32,59s12-4.424,12-6.764V48H31v-6h21.236 C56.535,42,59,38.355,59,32s-2.465-10-6.764-10H46v4.909C46,30.211,43.086,33,39.637,33H24.364z'
			/>
			<path
				fill='#8d6c9f'
				d='M37.652 40c-.553 0-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2C38.652 39.552 38.205 40 37.652 40zM42.218 40c-.553 0-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2C43.218 39.552 42.771 40 42.218 40zM46.782 40c-.553 0-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2C47.782 39.552 47.335 40 46.782 40zM51.348 40c-.553 0-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2C52.348 39.552 51.9 40 51.348 40z'
			/>
			<g>
				<path
					fill='#8d6c9f'
					d='M15.652 28.083c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C16.652 27.636 16.205 28.083 15.652 28.083zM20.217 28.083c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C21.217 27.636 20.77 28.083 20.217 28.083zM24.783 28.083c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C25.783 27.636 25.335 28.083 24.783 28.083zM29.348 28.083c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C30.348 27.636 29.9 28.083 29.348 28.083z'
				/>
			</g>
		</svg>
	);
};

export { RecatIcon, HTMLIcon, CSSIcon, JSIcon, PythonIcon };
