import React from 'react';

const Dot = ({ width = '30', height = '30' }) => {
	return (
		<svg height={height} viewBox='0 0 60 60' width={width} xmlns='http://www.w3.org/3000/svg'>
			<g id='026---Target' fill='none'>
				<g id='Icons' transform='translate(1 1)'>
					<path
						id='Shape'
						d='m58 29c-.0004652 7.9495901-3.2642918 15.550451-9.0282593 21.0251804-5.7639675 5.4747293-13.5226503 8.3432641-21.4617407 7.9348196h-.02c-15.4032699-.8098574-27.4773707-13.5354549-27.4773707-28.96s12.0741008-28.15014261 27.4773707-28.96h.02c7.9390904-.40844447 15.6977732 2.46009026 21.4617407 7.93481964 5.7639675 5.47472936 9.0277941 13.07559026 9.0282593 21.02518036z'
						fill='#0377bc'
					/>
					<path
						id='Shape'
						d='m55 29c-.0003518 15.4291761-12.0817537 28.1566188-27.49 28.96h-.02c-15.4032699-.8098574-27.4773707-13.5354549-27.4773707-28.96s12.0741008-28.15014261 27.4773707-28.96h.02c15.4082463.80338124 27.4896482 13.5308239 27.49 28.96z'
						fill='#02a9f4'
					/>
					<circle id='Oval' cx='29' cy='29' fill='#f5f5f5' r='18' />
					<circle id='Oval' cx='29' cy='29' fill='#f44335' r='7' />
				</g>
				<g id='Layer_2' fill='#000'>
					<path
						id='Shape'
						d='m30 0c-16.5685425 0-30 13.4314575-30 30s13.4314575 30 30 30 30-13.4314575 30-30c-.018737-16.5607751-13.4392249-29.98126296-30-30zm0 58c-15.463973 0-28-12.536027-28-28s12.536027-28 28-28 28 12.536027 28 28c-.0176345 15.4566626-12.5433374 27.9823655-28 28z'
					/>
					<path
						id='Shape'
						d='m29 6.02h.039c.318-.013.639-.02.961-.02.5522847 0 1-.44771525 1-1s-.4477153-1-1-1c-.348 0-.693.007-1.038.021-.5522847.01021727-.9917173.46621525-.9815 1.0185s.4662153.99171727 1.0185.9815z'
					/>
					<path
						id='Shape'
						d='m25.2 6.483c.5412391-.11045695.8904569-.63876095.78-1.18s-.6387609-.89045695-1.18-.78c-12.0879625 2.50132392-20.76790067 13.1329962-20.8 25.477 0 .5522847.44771525 1 1 1s1-.4477153 1-1c.02882353-11.3947059 8.0414611-21.208935 19.2-23.517z'
					/>
					<path
						id='Shape'
						d='m30 11c-10.4934102 0-19 8.5065898-19 19s8.5065898 19 19 19 19-8.5065898 19-19c-.0115735-10.4886126-8.5113874-18.9884265-19-19zm0 36c-9.3888407 0-17-7.6111593-17-17s7.6111593-17 17-17 17 7.6111593 17 17c-.010471 9.3845001-7.6154999 16.989529-17 17z'
					/>
					<path
						id='Shape'
						d='m30 22c-4.418278 0-8 3.581722-8 8s3.581722 8 8 8 8-3.581722 8-8c-.0049599-4.4162219-3.5837781-7.9950401-8-8zm0 14c-3.3137085 0-6-2.6862915-6-6s2.6862915-6 6-6 6 2.6862915 6 6c-.0033074 3.3123376-2.6876624 5.9966926-6 6z'
					/>
				</g>
			</g>
		</svg>
	);
};

const Hamburger = ({ width = '33', height = '20' }) => {
	return (
		<svg width={width} height={height} viewBox='0 0 33 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect width='33' height='3.83721' fill='black' />
			<rect y='7.67383' width='33' height='3.83721' fill='black' />
			<rect y='15.3496' width='33' height='3.83721' fill='black' />
		</svg>
	);
};

export { Dot, Hamburger };
